import { IUserInfo } from "types/interfaces/user-info";
import { IUserGroupSearchResult } from "types/interfaces/users/user-group-search-result";
import { Observable } from "rxjs";
import { VisitorInfo, VisitorsSearchFilter } from "types/interfaces/visitors";
import { PaginatedResult } from "types/interfaces/paginated-response";
import { IUserGroupSearchFilter } from "types/interfaces/users/user-group-search-filter";
import { ILoadUsersFilteredRequest_v3 } from "types/interfaces/user-filtered-request";

/**
 * FormSelection*Item are used for reactive forms integration.
 * It contains only the minimal information needed to identify a selection.
 */
export type FormSelectionUserItem = {
  type: "user";
  id: string;
};
export type FormSelectionGroupItem = {
  type: "group";
  id: string;
};
export type FormSelectionExternalEmailItem = {
  type: "externalEmail";
  email: string;
};
export type FormSelectionVisitorItem = {
  type: "visitor";
  visitor: VisitorInfo;
};
export type FormSelectionItem =
  | FormSelectionUserItem
  | FormSelectionGroupItem
  | FormSelectionExternalEmailItem
  | FormSelectionVisitorItem;

/**
 * SelectionItem is used for direct component usage with initiallySelected* inputs and selectionChange output.
 * It contains the full information about the selected item.
 */
export type SelectionUserItem = {
  type: "user";
  key: string;
  user: IUserInfo;
};
export type SelectionGroupItem = {
  type: "group";
  key: string;
  group: IUserGroupSearchResult;
};
export type SelectionExternalEmailItem = {
  type: "externalEmail";
  key: string;
  email: string;
};
export type SelectionVisitorItem = {
  type: "visitor";
  key: string;
  visitor: VisitorInfo;
};
export type SelectionItem =
  | SelectionUserItem
  | SelectionGroupItem
  | SelectionExternalEmailItem
  | SelectionVisitorItem;

/**
 * Service interfaces for loading different entity types
 */
export interface IUserSearchService {
  loadUsersForCompanyFiltered(
    params: ILoadUsersFilteredRequest_v3,
  ): Observable<PaginatedResult<IUserInfo>>;
}

export interface IGroupSearchService {
  loadGroupsForCompanyFiltered(
    params: IUserGroupSearchFilter,
  ): Observable<PaginatedResult<IUserGroupSearchResult>>;
}

export interface IVisitorSearchService {
  loadVisitorsForOffice(
    params: VisitorsSearchFilter,
  ): Observable<VisitorInfo[]>;
}

// For backward compatibility
export interface IUserFilterService
  extends IUserSearchService,
    IGroupSearchService {}
