import { UserStatus } from "types/enums";
import { FollowingStatusType } from "types/enums/following-status-type";
import { UserRole } from "types/enums/user-role";

export interface ILoadUsersFilteredRequest {
  companyId: string;
  searchQuery?: string;
  primaryOfficeIds?: number;
  roles?: string;
  userGroups?: string;
  status?: string;
  officeRoles?: string;
  onlyUsersWithNoGroups?: boolean;
  followStatus?: FollowingStatusType;
  userIds?: string[];
  excludeUserIds?: string[];
  sortField?: string;
  sortOrder?: string;
  offset: number;
  limit: number;
}

export interface ILoadUsersFilteredRequest_v3 {
  companyId?: string;
  searchQuery?: string;
  primaryOfficeIds?: (number | string)[];
  roles?: UserRole[];
  userGroups?: (number | string)[];
  onlyUsersWithNoGroups?: boolean;
  officeRolesIds?: (number | string)[];
  followStatus?:
    | FollowingStatusType.FOLLOWING
    | FollowingStatusType.NOT_FOLLOWING;
  userIds?: (number | string)[];
  userUuids?: string[];
  excludeUserIds?: (number | string)[];
  /** Status filter available only for admin/manager api usage */
  status?: UserStatus[];
  sortField?: string;
  sortOrder?: string;
  offset?: number;
  limit?: number;
  include?: IncludeAvailableProperties[];
  /** Desk area filter available for bookableUsers api usage */
  deskAreaId?: string;
}

export type IncludeAvailableProperties =
  | "status"
  | "role"
  | "userGroupIds"
  | "officeRoles";

export type AvailableAdditionalQueryParameters = "userGroups";
export type UsersQueryParameters = Partial<{
  [QUERY_PARAMETER in AvailableAdditionalQueryParameters]: ILoadUsersFilteredRequest_v3[QUERY_PARAMETER];
}>;
