import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from "@angular/core";
import { CommonModule, I18nPluralPipe } from "@angular/common";
import moment from "moment";
import {
  getTimeDifferenceFutureMap,
  getTimeDifferencePastMap,
  getTimeMonthsFutureMap,
  getTimeMonthsPastMap,
  getTimeYearsFutureMap,
  getTimeYearsPastMap,
} from "./time-difference-maps";

type TimeUnit = "days" | "months" | "years";

interface TimeDifferenceResult {
  value: number;
  timeUnit: TimeUnit;
  isPast: boolean;
}

/**
 * Component that displays the time difference between a given date and today in a human-readable format.
 * Automatically selects the most appropriate time unit (days, months, years) based on the difference.
 */
@Component({
  selector: "db-time-difference",
  standalone: true,
  imports: [CommonModule, I18nPluralPipe],
  template: `
    @if (diffResult() !== null) {
      {{ diffResult()?.value | i18nPlural: pluralizationMap() }}
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeDifferenceComponent {
  /** The date to calculate the difference from today */
  date = input<Date | string | number | null>(null);

  /**
   * Computed property that calculates the time difference between the input date and today,
   * returning a structured result with the value, time unit, and whether it's past or future.
   */
  diffResult = computed<TimeDifferenceResult | null>(() =>
    this.calculateTimeDifference(),
  );

  /**
   * Computed property that returns the appropriate pluralization map based on
   * the time unit (days, months, years) and direction (past/future).
   */
  pluralizationMap = computed<{ [k: string]: string }>(() => {
    if (!this.diffResult()) return getTimeDifferenceFutureMap();

    const { timeUnit, isPast } = this.diffResult()!;

    switch (timeUnit) {
      case "days":
        return isPast
          ? getTimeDifferencePastMap()
          : getTimeDifferenceFutureMap();
      case "months":
        return isPast ? getTimeMonthsPastMap() : getTimeMonthsFutureMap();
      case "years":
        return isPast ? getTimeYearsPastMap() : getTimeYearsFutureMap();
      default:
        return getTimeDifferenceFutureMap();
    }
  });

  /**
   * Calculates the time difference between the input date and today,
   * choosing the appropriate unit (days, months, years) based on the difference.
   * @returns A TimeDifferenceResult object or null if no date is provided
   */
  private calculateTimeDifference(): TimeDifferenceResult | null {
    if (!this.date()) return null;

    const targetDate = moment(this.date()).startOf("day");
    const today = moment().startOf("day");
    const diffDays = targetDate.diff(today, "days");

    const isPast = diffDays < 0;
    const absDiffDays = Math.abs(diffDays);

    if (absDiffDays >= 365) {
      return {
        value: Math.floor(absDiffDays / 365),
        timeUnit: "years",
        isPast,
      };
    }

    if (absDiffDays >= 30) {
      return {
        value: Math.floor(absDiffDays / 30),
        timeUnit: "months",
        isPast,
      };
    }

    return { value: absDiffDays, timeUnit: "days", isPast };
  }
}
