import { IBookingViewProps, IDayOpeningHours, IUserInfo, IUserSpaceView, IUserView } from 'types';
import { IOfficeEvent } from './office-events';

export interface IDayViewMySelectedOption {
  // ID of selected status option
  optionId: string;
  bookingIds: string[];
  schedulingEntryId: string;
  officeId: string;
  officeName: string;
  canBeRemoved: boolean;
  bookings: IBookingViewProps[];
  guestBookings: IBookingViewProps[];
  userSpacesInfo: IUserSpaceView[];
  hrisLogo?: string;
}

export interface IDayView {
  // Day in format YYYY-MM-DD
  readonly day: string;
  readonly totalOthersCount: number;
  readonly guestsCount: number;
  readonly mySelectedOption?: IDayViewMySelectedOption;
  readonly favUserData: IUserView[];
  readonly officeEvents?: IOfficeEvent[];

  readonly officeId: string;
  readonly officeName: string;
  readonly isOfficeClosed: boolean;
  readonly timeZone?: string;
  readonly operatingHours: IDayOpeningHours | null;
  readonly lastUpdatedBy?: IUserInfo & { updatedAt: string };

  // props set by UI only
  isDummyValue?: boolean;
  isRemovingStatus: boolean;
  isCreatingStatus: boolean;
  showWeeksDivider?: boolean;
  showFollowColleaguesPrompt?: boolean;
  showBookASpaceButton?: boolean;
  isOutsideOfficeLeadTime?: boolean;
  showUserSpaces?: boolean;
  showBookings?: boolean;
  height?: number;
}
