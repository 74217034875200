import { Params } from '@angular/router';
import { IMeetingRoomCalendarData, IMeetingRoomCalendarEventData, IMeetingRoomKioskData, IMeetingRoomWithFloor } from 'types/interfaces';
import { getEventsForMeetingRoom } from './get-events-for-meeting-room';
import { formatTime } from '../time-formatter';

export const generateTimeBlockersForDay = (
  timezone: string,
  meetingRooms: IMeetingRoomWithFloor[],
  meetingRoomCalendarData: IMeetingRoomCalendarData[] | null,
  routeParams: Params
) => {
  const { meetingRoomId, seriesId, virtualId, bookingId } = routeParams;

  const meetingRoomData = (meetingRooms || []).find((mr) => mr.id === meetingRoomId);
  const meetingRoomEvents = getEventsForMeetingRoom(meetingRoomData!, meetingRoomCalendarData!);
  if (!meetingRoomData) {
    return [];
  }

  const filterEventBeingEdited = (event: IMeetingRoomCalendarEventData): boolean => {
    const isEventBeingEdited =
      (!!seriesId && !!virtualId && event.seriesId === seriesId && event.virtualId === virtualId) ||
      (!!bookingId && event.id === bookingId);
    return !isEventBeingEdited;
  };

  return meetingRoomEvents.filter(filterEventBeingEdited).map((event) => ({
    startTime: formatTime(timezone, event.startTime),
    endTime: formatTime(timezone, event.endTime),
  }));
};

export const generateKioskTimeBlockersForDay = (kioskData: IMeetingRoomKioskData) => {
  const { events } = kioskData;

  return events.map((event) => ({
    startTime: formatTime(kioskData.meetingRoom.timezone, event.startTime),
    endTime: formatTime(kioskData.meetingRoom.timezone, event.endTime),
  }));
};
