<db-chip
  [removable]="removable"
  [removeIcon]="removeIcon"
  [showBadge]="!!showBadge"
  [badgeLabel]="badgeLabel"
  [size]="size()"
>
  @if (useGuestAvatar()) {
    <db-guest-avatar
      class="block ml-[-0.75rem] mr-[0.5rem]"
      [avatarSize]="guestAvatarSize()"
    />
  } @else {
    @if (avatarImgSrc) {
      <img [src]="avatarImgSrc" alt="avatar" />
    }
    @if (!avatarImgSrc) {
      <p-avatar
        [size]="size()"
        [label]="label || '' | exec: getAbbreviation"
        [style]="{ 'background-color': avatarColor, color: '#fff' }"
        shape="circle"
      >
      </p-avatar>
    }
  }
  <div id="avatar-chip-content" [ngStyle]="contentStyles">
    {{ label }}
    <ng-content></ng-content>
  </div>
</db-chip>
