import { CommonModule } from "@angular/common";
import { NgModule, Optional, SkipSelf } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";

import { SlideOverModule } from "slide-over-module";
import { LottiePlayerComponent } from "lottie-module";

import { AUTH_STORE_NAME } from "./constants";
import {
  AuthRoutingModule,
  ComponentReuseStrategy,
} from "./auth-routing.module";
import { authReducer } from "./+store/reducer";
import { AuthEffects } from "./+store/effects";

import { CheckInComponent } from "./check-in/check-in.component";
import { NotActiveUserComponent } from "./not-active-user/not-active-user.component";
import { NotActiveCompanyComponent } from "./not-active-company/not-active-company.component";
import { SignInComponent } from "./sign-in/sign-in.component";
import { SignInEmailComponent } from "./sign-in-email/sign-in-email.component";
import { SignInWizardComponent } from "./sign-in-wizard/sign-in-wizard.component";
import { VerifyEmailComponent } from "./verify-email/verify-email.component";
import { AuthComponent } from "./auth/auth.component";
import { APIInterceptorProvider } from "./api.interceptor";
import { RouteReuseStrategy } from "@angular/router";
import { DBCommonModule } from "common-module";
import { UploadModule } from "upload-module";
import { CardComponent } from "./card/card.component";
import { NoAccessComponent } from "./no-access/no-access.component";
import { HttpClientJsonpModule } from "@angular/common/http";
import { SignOutConfirmationComponent } from "./sign-out-confirmation";
import { SamlComponent } from "./saml/saml.component";
import { UserNamesComponent } from "./user-names/user-names.component";
import { InputTextModule } from "primeng/inputtext";
import { ButtonModule } from "primeng/button";
import { SlackComponent } from "./slack/slack.component";
import { ExecPipe, TrimDirective } from "shared";
import { OverlayPanelModule } from "primeng/overlaypanel";
import {
  LoadingTenseComponent,
  ProgressBarComponent,
  ButtonComponent,
  ButtonLinkComponent,
  DotLoaderComponent,
  DialogContainerComponent,
} from "db-ui";
import { featureFlagReducer } from "./+store/feature-flag/reducer";
import { FEATURE_FLAG_STORE_NAME } from "./constants-feature-flags";
import { FeatureFlagEffects } from "./+store/feature-flag/effects";
import { FreeTrialComponent } from "./free-trial/free-trial.component";
import { LoaderModule } from "loader-module";

const primeImports = [InputTextModule, ButtonModule, OverlayPanelModule];

const localModules = [SlideOverModule, DBCommonModule];

const dbUiComponents = [
  DotLoaderComponent,
  ButtonComponent,
  ButtonLinkComponent,
];

@NgModule({
  declarations: [
    AuthComponent,
    CheckInComponent,
    NotActiveUserComponent,
    NotActiveCompanyComponent,
    SignInComponent,
    SignInEmailComponent,
    SignInWizardComponent,
    VerifyEmailComponent,
    CardComponent,
    NoAccessComponent,
    SamlComponent,
    UserNamesComponent,
    SlackComponent,
    FreeTrialComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    AuthRoutingModule,
    LoaderModule,
    UploadModule,
    HttpClientJsonpModule,
    TrimDirective,
    StoreModule.forFeature(AUTH_STORE_NAME, authReducer),
    EffectsModule.forFeature([AuthEffects]),
    StoreModule.forFeature(FEATURE_FLAG_STORE_NAME, featureFlagReducer),
    EffectsModule.forFeature([FeatureFlagEffects]),
    ProgressBarComponent,
    DialogContainerComponent,
    LoadingTenseComponent,
    SignOutConfirmationComponent,
    ExecPipe,
    ButtonComponent,
    LottiePlayerComponent,
    ...localModules,
    ...primeImports,
    ...dbUiComponents,
  ],
  providers: [
    APIInterceptorProvider,
    { provide: RouteReuseStrategy, useClass: ComponentReuseStrategy },
  ],
  exports: [AuthComponent],
})
export class AuthModule {
  constructor(@SkipSelf() @Optional() module: AuthModule) {
    if (module) {
      throw new Error("AuthModule should be imported only once!");
    }
  }
}
