import { ConferenceLinkType } from 'types/enums';
import { ICateringCategory } from './catering-category';
import { IUserViewInfo } from '../user-info';
import { IOfficeFloorBasicInfo } from '../offices/office-floor';
import { IRequestedCategory } from '../service-request';

export interface IMeetingRoomCalendarDataAccessRules {
  isHidden: boolean;
  isRestricted: boolean;
  userHasAccess: boolean;
  restrictedTime: number;
}

export interface IMeetingRoom {
  id: string;
  name: string;
  capacity: number;
  description: string;
  amenities: string[];
  floorId: string;
  accessRules: IMeetingRoomCalendarDataAccessRules;
  images: string[];
  order: number;
  equipment: IEquipment[];

  conferenceProviders: ConferenceLinkType[];
  syncProvider: string | null;

  serviceRequestCategories?: ICateringCategory[];
}

export interface IMeetingRoomWithFloor extends IMeetingRoom {
  floor: IOfficeFloorBasicInfo;
}

export interface IMeetingRoomCalendarData {
  id: string;
  events: IMeetingRoomCalendarEventData[];
}

export interface IMeetingRoomWithCalendarData extends IMeetingRoom {
  events: IMeetingRoomCalendarData['events'];
}

export interface IMeetingRoomCalendarEventData {
  id?: string;
  bookingId?: string;

  subject: string;
  startTime: string;
  endTime: string;

  type: 'occurrence' | 'legacy' | 'single';
  roomId: string;
  shouldMasquerade: boolean;
  hasServiceRequest?: boolean;

  user: {
    id: string;
    avatarUrl: string;
    firstName: string;
    lastName: string;
    avatarColor: string;
    profileImage?: string;
  };

  virtualId: string;
  seriesId: string;

  isEditable: boolean;
  isPrivate: boolean;
}

export interface IMeetingRoomKioskEventData {
  id: string;
  subject: string;
  startTime: string;
  endTime: string;

  hasServiceRequest: boolean;
  checkIn: {
    checkedIn: boolean;
    autoCancellationAt: string;
  };

  owner: IUserViewInfo;

  // Not returned by API but possibly needed for UI
  bookingId?: string;
  type: 'occurrence' | 'legacy' | 'single';
  roomId: string;
  virtualId: string;
  seriesId: string;
}

export interface IMeetingRoomKioskEventDetailsData extends IMeetingRoomKioskEventData {
  description: string;
  serviceRequest: {
    requestedCategories: IRequestedCategory[];
  };
  attendees: {
    id: string;
    firstName?: string;
    lastName?: string;
    avatarColor?: string;
    profileImage?: string;
    email?: string;
    type: string;
  }[];
}

export interface IEquipment {
  id: string;
  title: string;
}

export interface IEquipmentDto {
  id: string;
  title?: string;
}
