import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IVisitorSearchService } from "db-ui";
import { VisitorInfo, VisitorsSearchFilter } from "types/interfaces/visitors";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class VisitorsSearchService implements IVisitorSearchService {
  private readonly http = inject(HttpClient);

  loadVisitorsForOffice({
    officeId,
    q,
  }: VisitorsSearchFilter): Observable<VisitorInfo[]> {
    const url = `/api/visitors/visitor-directory/${officeId}`;
    const queryParams = q ? `?q=${q}` : "";
    return this.http.get<VisitorInfo[]>(`${url}${queryParams}`);
  }
}
