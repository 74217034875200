import { CategoryItemTypeValue } from "./meeting-rooms/catering-category";

export interface ServiceRequest {
  requestedCategories: RequestedCategory[];
}

export interface RequestedCategory {
  categoryId: string;
  title?: string;
  requestedItems: RequestedItem[];
  notes?: string;
  bufferBeforeMinutes?: number;
  bufferAfterMinutes?: number;
  costCenterCode?: string;
}

export interface RequestedItem {
  itemId: string;
  title?: string;
  itemType: CategoryItemTypeValue;
}
