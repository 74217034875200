import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { IOfficeEvent, IUserGroup } from 'common-module';
import {
  loadOfficeEventsBundle,
  selectOfficeEventBundle,
  saveOfficeEventBundle,
  deleteOfficeEventBundle,
  attendOfficeEventBundle,
  loadInvitableUserGroupsBundle,
  loadInvitableUsersBundle
} from './bundles';
import { GridDatePeriodType, IUserInfo } from 'types';

const sortComparer = (a: IOfficeEvent, b: IOfficeEvent) => a.id.localeCompare(b.id);

const adapter = createEntityAdapter<IOfficeEvent>({
  selectId: (entity: IOfficeEvent) => entity.id,
  sortComparer,
});

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export interface IOfficeEventsState extends EntityState<IOfficeEvent> {
  invitableUserGroups: IUserGroup[] | null;
  invitableUsers: IUserInfo[] | null;
  totalInvitableUsers: number;
  selectedEvent: IOfficeEvent | null;
  isSelectedEventLoading: boolean;
  isListLoading: boolean;
  listDatePeriodType: GridDatePeriodType | null;
}

export const initialState: IOfficeEventsState = {
  ...adapter.getInitialState({}),
  invitableUserGroups: null,
  invitableUsers: null,
  totalInvitableUsers: 0,
  selectedEvent: null,
  isSelectedEventLoading: false,
  isListLoading: false,
  listDatePeriodType: null,
};

export const officeEventsReducer = createReducer(
  initialState,
  on(loadOfficeEventsBundle.loadOfficeEvents, (state) => {
    return {
      ...state,
      isListLoading: true,
    };
  }),
  on(loadOfficeEventsBundle.loadOfficeEventsSuccess, (state, { events, gridDatePeriodType }) => {
    const cleanState = {
      ...state,
      ...adapter.removeAll(state),
    };

    return {
      ...cleanState,
      ...adapter.addMany(events, cleanState),
      isListLoading: false,
      listDatePeriodType: gridDatePeriodType ?? null,
    };
  }),
  on(loadOfficeEventsBundle.loadOfficeEventsFailure, (state) => {
    return {
      ...state,
      isListLoading: false,
    };
  }),
  on(loadInvitableUserGroupsBundle.loadInvitableUserGroupsSuccess, (state, payload) => {
    const { userGroups } = payload;
    return {
      ...state,
      invitableUserGroups: userGroups,
    };
  }),
  on(loadInvitableUsersBundle.loadInvitableUsersSuccess, (state, payload) => {
    const { users, totalUsers } = payload;
    return {
      ...state,
      totalInvitableUsers: totalUsers,
      invitableUsers: users,
    };
  }),
  on(selectOfficeEventBundle.selectOfficeEvent, (state) => {
    return {
      ...state,
      isSelectedEventLoading: true,
    };
  }),
  on(selectOfficeEventBundle.selectOfficeEventSuccess, (state, { event }) => {
    return {
      ...adapter.updateOne({ id: event.id, changes: event }, state),
      selectedEvent: event,
      isListLoading: false,
    };
  }),
  on(selectOfficeEventBundle.selectOfficeEventFailure, (state) => {
    return {
      ...state,
      isSelectedEventLoading: false,
    };
  }),
  on(selectOfficeEventBundle.selectOfficeEventCleanup, (state) => {
    return {
      ...state,
      selectedEvent: null,
    };
  }),
  on(saveOfficeEventBundle.saveOfficeEventSuccess, (state, { event }) => {
    if (state.listDatePeriodType === GridDatePeriodType.PAST) {
      // We don't want to add new entities in the store when the list is filtered by past events
      return {
        ...state,
        ...adapter.updateOne({ id: event.id, changes: event }, state),
      };
    } else {
      return {
        ...state,
        ...adapter.upsertOne(event, state),
      };
    }
  }),

  on(deleteOfficeEventBundle.deleteOfficeEventSuccess, (state, { event }) => {
    return {
      ...state,
      ...adapter.removeOne(event.id, state),
    };
  }),

  on(attendOfficeEventBundle.attendOfficeEventSuccess, (state, { event }) => adapter.updateOne({ id: event.id, changes: event }, state))
);

export const selectOfficeEventIds = selectIds;
export const selectOfficeEventEntities = selectEntities;
export const selectAllOfficeEvents = selectAll;
export const selectOfficeEventsTotalCount = selectTotal;
