import { IMeetingRoom, IMeetingRoomCalendarData, IMeetingRoomCalendarEventData } from "types/interfaces";

export const getEventsForMeetingRoom = (
  meetingRoom: IMeetingRoom,
  meetingRoomCalendarData: IMeetingRoomCalendarData[]
): IMeetingRoomCalendarEventData[] => {
  if (!meetingRoom || !meetingRoomCalendarData) {
    return [];
  }
  return meetingRoomCalendarData.find((d) => d.id === meetingRoom.id)?.events || [];
};