import { UserStatus } from 'types/enums';
import { IUserInfo } from 'types/interfaces';

export const userIsActive = (userInfo: IUserInfo): boolean => {
  return !userInfo?.status || userInfo?.status === UserStatus.Active;
};

export const canOpenProfile = (userInfo: IUserInfo, authUser: IUserInfo | null): boolean => {
  if (!userInfo || !authUser || userInfo.isGuest) {
    return false;
  }
  return (userInfo?.id ?? userInfo?.userId) != authUser?.id && !userInfo?.isAnonymous && userIsActive(userInfo);
};

export const userName = (userInfo?: IUserInfo, emailAsFallback = false): string => {
  if (!userInfo) {
    return '';
  }

  const firstLastName = userInfo.firstName ? `${userInfo.firstName} ${userInfo.lastName}` : '';
  const userName = (userInfo.isAnonymous && firstLastName === 'Anonymous user')
    ? $localize`:@@common|anonymous-user:Anonymous user`
    : firstLastName;
  const userNameWithEmailAsFallback = (emailAsFallback && !userName.length)
    ? userInfo.email || ''
    : userName;

  return userInfo.isGuest ? `${userNameWithEmailAsFallback} ${$localize`:@@common|guest-with-brackets:(Guest)`}` : userNameWithEmailAsFallback;
}
