export const AvatarSizes = [
  "micro",
  "micro-no-border",
  "mini",
  "standard",
  "x-mini",
  "mini-no-border",
  "standard-no-border",
  "x-large-no-border",
] as const;
export const GuestAvatarSizes = [
  "micro",
  "micro-no-border",
  "mini",
  "mini-no-border",
  "standard",
] as const;
export const UserGroupAvatarSizes = [
  "micro",
  "mini",
  "mini-no-border",
  "standard",
] as const;

export type AvatarSize = (typeof AvatarSizes)[number];
export type GuestAvatarSize = (typeof GuestAvatarSizes)[number];
export type UserGroupAvatarSize = (typeof UserGroupAvatarSizes)[number];
