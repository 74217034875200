/**
 * Functions for i18n plural messages based on time units
 */

export function getTimeDifferenceFutureMap(): { [k: string]: string } {
  return {
    "=0": $localize`:@@common|time-difference|today:Today`,
    "=1": $localize`:@@common|time-difference|tomorrow:Tomorrow`,
    "=2": $localize`:@@common|time-difference|in-two-days:In 2 days`,
    few: $localize`:@@common|time-difference|in-few-days:In a few days`,
    many: $localize`:@@common|time-difference|in-many-days:In many days`,
    other: $localize`:@@common|time-difference|in-days:In # days`,
  };
}

export function getTimeDifferencePastMap(): { [k: string]: string } {
  return {
    "=0": $localize`:@@common|time-difference|today:Today`,
    "=1": $localize`:@@common|time-difference|yesterday:Yesterday`,
    "=2": $localize`:@@common|time-difference|two-days-ago:2 days ago`,
    few: $localize`:@@common|time-difference|few-days-ago:A few days ago`,
    many: $localize`:@@common|time-difference|many-days-ago:Many days ago`,
    other: $localize`:@@common|time-difference|days-ago:# days ago`,
  };
}

export function getTimeMonthsFutureMap(): { [k: string]: string } {
  return {
    "=1": $localize`:@@common|time-difference|in-month:In 1 month`,
    "=2": $localize`:@@common|time-difference|in-two-months:In 2 months`,
    few: $localize`:@@common|time-difference|in-few-months:In a few months`,
    many: $localize`:@@common|time-difference|in-many-months:In many months`,
    other: $localize`:@@common|time-difference|in-months:In # months`,
  };
}

export function getTimeMonthsPastMap(): { [k: string]: string } {
  return {
    "=1": $localize`:@@common|time-difference|month-ago:1 month ago`,
    "=2": $localize`:@@common|time-difference|two-months-ago:2 months ago`,
    few: $localize`:@@common|time-difference|few-months-ago:A few months ago`,
    many: $localize`:@@common|time-difference|many-months-ago:Many months ago`,
    other: $localize`:@@common|time-difference|months-ago:# months ago`,
  };
}

export function getTimeYearsFutureMap(): { [k: string]: string } {
  return {
    "=1": $localize`:@@common|time-difference|in-year:In 1 year`,
    "=2": $localize`:@@common|time-difference|in-two-years:In 2 years`,
    few: $localize`:@@common|time-difference|in-few-years:In a few years`,
    many: $localize`:@@common|time-difference|in-many-years:In many years`,
    other: $localize`:@@common|time-difference|in-years:In # years`,
  };
}

export function getTimeYearsPastMap(): { [k: string]: string } {
  return {
    "=1": $localize`:@@common|time-difference|year-ago:1 year ago`,
    "=2": $localize`:@@common|time-difference|two-years-ago:2 years ago`,
    few: $localize`:@@common|time-difference|few-years-ago:A few years ago`,
    many: $localize`:@@common|time-difference|many-years-ago:Many years ago`,
    other: $localize`:@@common|time-difference|years-ago:# years ago`,
  };
}
