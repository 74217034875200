import {
  ChangeDetectorRef,
  Directive,
  inject,
  input,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  SelectionItem,
  IUserSearchService,
  IGroupSearchService,
  IVisitorSearchService,
  IUserFilterService,
} from "./autocomplete-users.types";
import {
  Subject,
  startWith,
  takeUntil,
  distinctUntilChanged,
  debounceTime,
  switchMap,
  of,
  Observable,
  map,
  combineLatest,
} from "rxjs";
import {
  IUserInfo,
  IUserGroupSearchResult,
  UserStatus,
  UsersQueryParameters,
} from "types";
import {
  AutoCompleteCompleteEvent,
  AutoCompleteSelectEvent,
  AutoCompleteUnselectEvent,
} from "primeng/autocomplete";
import { VisitorInfo } from "types/interfaces/visitors";

const SEARCH_DEBOUNCE_TIME = 200;
const DEFAULT_LIMIT = 100;

@Directive({
  standalone: true,
})
/** Base logic for autocomplete with users/groups for single and multi select case */
export abstract class AutocompleteUsersComponentBase
  implements OnInit, OnDestroy
{
  @Input() companyId!: string;
  @Input() officeId?: string;
  @Input() placeholder: string =
    $localize`:@@user-module|user-search|user-search-placeholder:Search colleagues by name or email`;
  @Input() emptyMessage: string =
    $localize`:@@user-module|user-search|no-users:No colleagues found`;
  @Input() limit = DEFAULT_LIMIT;
  @Input() excludeUserIds: string[] = [];

  /**
   * @deprecated Use userSearchService, groupSearchService, and visitorSearchService instead
   */
  @Input() userService?: IUserFilterService;

  @Input() userSearchService?: IUserSearchService;
  @Input() groupSearchService?: IGroupSearchService;
  @Input() visitorSearchService?: IVisitorSearchService;

  @Input() label?: string;
  @Input() showAsterisk = false;
  @Input() optional = false;

  @Input() enableUserSelection = true;
  @Input() enableGroupSelection = false;
  @Input() enableVisitorSelection = false;
  @Input() useUuid = false;
  /** Defines the user statuses to be included in the autocomplete search. Filtering is available only for roles with admin access. */
  @Input() usersStatusFilter: UserStatus[] = [];
  additionalUserQueryParameters = input<UsersQueryParameters>();

  @Input() protected abstract dataTestId: string;
  @Input() protected abstract filterDeskAreaId: string;
  @Input() abstract showClear: boolean;

  /** As prime autocomplete supports `multiple` input (true or false) BUT `selectedItem` template is not supported when `[multiple]="false"` (https://primeng.org/autocomplete#api.autocomplete.templates.selectedItem), the implementation of single selection is enforced via that custom flag */
  protected abstract allowMultipleSelectedItems: boolean;
  protected readonly destroy$ = new Subject<void>();
  protected readonly searchSubject = new Subject<string>();
  protected readonly cdRef = inject(ChangeDetectorRef);

  currentSelection: SelectionItem[] = []; // even when single select, we have to use array to keep the same interface (see allowMultipleSelectedItems)
  suggestions: SelectionItem[] = [];

  constructor() {
    this.initializeSearchSubscription();
  }

  protected abstract addSuggestions(
    users: IUserInfo[],
    groups: IUserGroupSearchResult[],
    visitors: VisitorInfo[],
    searchQuery: string,
  ): SelectionItem[];
  protected abstract emitCurrentSelection(): void;

  ngOnInit(): void {
    this.validateDependencies();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSearch(event: AutoCompleteCompleteEvent): void {
    this.searchSubject.next(event?.query?.trim());
  }

  onSelect(event: AutoCompleteSelectEvent): void {
    const selectedItem = event.value as SelectionItem;
    if (!this.allowMultipleSelectedItems) {
      this.currentSelection = [selectedItem];
    } else {
      this.currentSelection = [...this.currentSelection, selectedItem];
    }
    this.emitCurrentSelection();
    this.searchSubject.next("");
  }

  onUnselect(event: AutoCompleteUnselectEvent): void {
    const unselectedItem = event.value as SelectionItem;
    if (!this.allowMultipleSelectedItems) {
      this.currentSelection = [];
    } else {
      this.currentSelection = this.currentSelection.filter(
        (item) => item.key !== unselectedItem.key,
      );
    }
    this.emitCurrentSelection();
  }

  onBlur(): void {
    this.searchSubject.next("");
  }

  onClear(): void {
    this.currentSelection = [];
    this.emitCurrentSelection();
  }

  /**
   * Get the effective user service (either from new input or fallback to legacy service)
   */
  protected getUserService(): IUserSearchService | null {
    return this.userSearchService || this.userService || null;
  }

  /**
   * Get the effective group service (either from new input or fallback to legacy service)
   */
  protected getGroupService(): IGroupSearchService | null {
    return this.groupSearchService || this.userService || null;
  }

  protected loadUsersById(ids: string[]): Observable<IUserInfo[]> {
    const userService = this.getUserService();
    if (!ids.length || !this.enableUserSelection || !userService) {
      return of([]);
    }

    return userService
      .loadUsersForCompanyFiltered({
        companyId: this.companyId,
        offset: 0,
        limit: ids.length,
        status: this.usersStatusFilter,
        deskAreaId: this.filterDeskAreaId,
        ...(this.useUuid ? { userUuids: ids } : { userIds: ids }),
      })
      .pipe(
        map((response) => response.data),
        takeUntil(this.destroy$),
      );
  }

  protected loadGroupsById(
    groupIds: string[],
  ): Observable<IUserGroupSearchResult[]> {
    const groupService = this.getGroupService();
    if (!groupIds.length || !this.enableGroupSelection || !groupService) {
      return of([]);
    }
    return groupService
      .loadGroupsForCompanyFiltered({
        companyId: this.companyId,
        groupIds,
        include: ["userCount"],
        userStatuses: this.usersStatusFilter,
        offset: 0,
        limit: groupIds.length,
      })
      .pipe(
        map((response) => response.data),
        takeUntil(this.destroy$),
      );
  }

  protected mapUserToSelectionItem(user: IUserInfo): SelectionItem {
    return {
      type: "user",
      key: `user-${user.id}`,
      user,
    };
  }

  protected mapGroupToSelectionItem(
    group: IUserGroupSearchResult,
  ): SelectionItem {
    return {
      type: "group",
      key: `group-${group.id}`,
      group,
    };
  }

  protected mapVisitorToSelectionItem(visitor: VisitorInfo): SelectionItem {
    return {
      type: "visitor",
      key: `visitor-${visitor.email}`,
      visitor,
    };
  }

  private initializeSearchSubscription(): void {
    this.searchSubject
      .pipe(
        startWith(""),
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        debounceTime(SEARCH_DEBOUNCE_TIME),
        switchMap((searchValue) => {
          if (!searchValue || !this.companyId) {
            return of([]);
          }

          let usersRequest$: Observable<IUserInfo[]> = of([]);
          if (this.enableUserSelection) {
            const userService = this.getUserService();
            if (userService) {
              usersRequest$ = userService
                .loadUsersForCompanyFiltered({
                  companyId: this.companyId,
                  searchQuery: searchValue,
                  excludeUserIds: [
                    ...this.excludeUserIds,
                    ...this.getSelectedUserIds(),
                  ],
                  offset: 0,
                  limit: this.limit,
                  status: this.usersStatusFilter,
                  deskAreaId: this.filterDeskAreaId,
                  ...this.additionalUserQueryParameters(),
                })
                .pipe(map((response) => response.data));
            }
          }

          let groupsRequest$: Observable<IUserGroupSearchResult[]> = of([]);
          if (this.enableGroupSelection) {
            const groupService = this.getGroupService();
            if (groupService) {
              groupsRequest$ = groupService
                .loadGroupsForCompanyFiltered({
                  companyId: this.companyId,
                  searchQuery: searchValue,
                  excludeGroupIds: this.getSelectedGroupIds(),
                  include: ["userCount"],
                  userStatuses: this.usersStatusFilter,
                  offset: 0,
                  limit: this.limit,
                })
                .pipe(map((response) => response.data));
            }
          }

          let visitorsRequest$: Observable<VisitorInfo[]> = of([]);
          if (
            this.enableVisitorSelection &&
            this.officeId &&
            this.visitorSearchService
          ) {
            visitorsRequest$ = this.visitorSearchService.loadVisitorsForOffice({
              officeId: this.officeId,
              q: searchValue,
            });
          }

          return combineLatest([
            usersRequest$,
            groupsRequest$,
            visitorsRequest$,
          ]).pipe(
            map(([users, groups, visitors]) =>
              this.addSuggestions(users, groups, visitors, searchValue),
            ),
          );
        }),
      )
      .subscribe((suggestions) => {
        this.suggestions = suggestions;
        this.cdRef.detectChanges();
      });
  }

  private getSelectedUserIds(): string[] {
    if (!this.currentSelection) {
      return [];
    }

    return this.currentSelection
      .filter(
        (item): item is Extract<SelectionItem, { type: "user" }> =>
          item.type === "user",
      )
      .map((item) => item.user.id!);
  }

  private getSelectedGroupIds(): string[] {
    if (!this.currentSelection) {
      return [];
    }

    return this.currentSelection
      .filter(
        (item): item is Extract<SelectionItem, { type: "group" }> =>
          item.type === "group",
      )
      .map((item) => item.group.id);
  }

  // Type guard to ensure visitorSearchService and officeId are provided when enableVisitorSelection is true
  private validateDependencies(): asserts this is this & {
    visitorSearchService: IVisitorSearchService;
    officeId: string;
  } {
    if (this.enableVisitorSelection) {
      if (!this.visitorSearchService) {
        throw new Error(
          "visitorSearchService is required when enableVisitorSelection is true",
        );
      }
      if (!this.officeId) {
        throw new Error(
          "officeId is required when enableVisitorSelection is true",
        );
      }
    }
  }
}
