<!-- WEB APP -->

<db-menu-item
  [link]="[clientPartPrefix, 'default']"
  [style]="'nav-with-border-admin'"
  imgSrc="/assets/icons/arrow-back.svg"
  [title]="menuItemLabels['web-app']"
>
</db-menu-item>

<br />

<!-- OFFICE CONFIGURATION -->
@if (
  (isUserAdmin || isUserDeskbirdAdmin || isUserOfficeAdmin) &&
  ([adminPartPrefix, "company"] | exec: hasAccessToPage)
) {
  <db-menu-item-with-children
    *dbFeatureAccess="[
      FeatureAccessType.RESOURCE_BOOKING,
      FeatureAccessType.MEETING_ROOMS,
      FeatureAccessType.WORKPLACE_ENGAGEMENT,
    ]"
    [link]="[
      adminPartPrefix,
      'company',
      companyId,
      officeId ? 'office' : 'office-redirect',
      officeId ? officeId : 'default',
      'dashboard',
      'essentials',
    ]"
    [hideIcons]="hideIcons"
    [linkActiveOptions]="{ exact: true }"
    queryParamsHandling="merge"
    [style]="'admin'"
    imgSrc="/assets/icons/configuration.svg"
    [title]="menuItemLabels['office-configuration']"
  >
    @if (
      [
        adminPartPrefix,
        "company",
        companyId,
        officeId ? "office" : "office-redirect",
        officeId ? officeId : "default",
        "dashboard",
        "essentials",
      ] | exec: hasAccessToPage
    ) {
      <db-menu-item
        [style]="'admin'"
        [hideIcons]="hideIcons"
        [link]="[
          adminPartPrefix,
          'company',
          companyId,
          officeId ? 'office' : 'office-redirect',
          officeId ? officeId : 'default',
          'dashboard',
          'essentials',
        ]"
        [title]="menuItemLabels['office-settings']"
      >
      </db-menu-item>
    }
    @if (
      [
        adminPartPrefix,
        "company",
        companyId,
        officeId ? "office" : "office-redirect",
        officeId ? officeId : "default",
        "floors-and-spaces",
      ] | exec: hasAccessToPage
    ) {
      <db-menu-item
        *dbFeatureAccess="[
          FeatureAccessType.RESOURCE_BOOKING,
          FeatureAccessType.MEETING_ROOMS,
        ]"
        [style]="'admin'"
        [hideIcons]="hideIcons"
        [link]="[
          adminPartPrefix,
          'company',
          companyId,
          officeId ? 'office' : 'office-redirect',
          officeId ? officeId : 'default',
          'floors-and-spaces',
        ]"
        [title]="menuItemLabels['floors-spaces']"
      >
      </db-menu-item>
    }
  </db-menu-item-with-children>
}

<!-- DESKS, PARKING & OTHERS -->
@if (
  (isUserAdmin || isUserDeskbirdAdmin || isUserOfficeAdmin) &&
  ([adminPartPrefix, "company"] | exec: hasAccessToPage)
) {
  <db-menu-item-with-children
    *dbFeatureAccess="FeatureAccessType.RESOURCE_BOOKING"
    [link]="[
      adminPartPrefix,
      'company',
      companyId,
      officeId ? 'office' : 'office-redirect',
      officeId ? officeId : 'default',
      'dashboard',
      'booking-settings',
    ]"
    [hideIcons]="hideIcons"
    [linkActiveOptions]="{ exact: true }"
    queryParamsHandling="merge"
    [style]="'admin'"
    imgSrc="/assets/icons/book.svg"
    [title]="menuItemLabels['desk-other-resources']"
  >
    @if (
      [
        adminPartPrefix,
        "company",
        companyId,
        officeId ? "office" : "office-redirect",
        officeId ? officeId : "default",
        "dashboard",
        "booking-settings",
      ] | exec: hasAccessToPage
    ) {
      <db-menu-item
        [style]="'admin'"
        [hideIcons]="hideIcons"
        [link]="[
          adminPartPrefix,
          'company',
          companyId,
          officeId ? 'office' : 'office-redirect',
          officeId ? officeId : 'default',
          'dashboard',
          'booking-settings',
        ]"
        [title]="menuItemLabels['booking-settings']"
      >
      </db-menu-item>
    }
    @if (
      officeEquipmentEnabled &&
      ([
        adminPartPrefix,
        "company",
        companyId,
        officeId ? "office" : "office-redirect",
        officeId ? officeId : "default",
        "office-equipment",
      ] | exec: hasAccessToPage)
    ) {
      <db-menu-item
        [style]="'admin'"
        [hideIcons]="hideIcons"
        [link]="[
          adminPartPrefix,
          'company',
          companyId,
          officeId ? 'office' : 'office-redirect',
          officeId ? officeId : 'default',
          'office-equipment',
        ]"
        [title]="menuItemLabels['office-equipment']"
      >
      </db-menu-item>
    }

    <db-menu-item
      [style]="'admin'"
      [hideIcons]="hideIcons"
      [link]="[
        adminPartPrefix,
        'company',
        companyId,
        officeId ? 'office' : 'office-redirect',
        officeId ? officeId : 'default',
        'bookings',
      ]"
      queryParamsHandling="merge"
      [title]="menuItemLabels['bookings']"
    >
    </db-menu-item>
  </db-menu-item-with-children>
}

<!-- MEETING ROOM CONFIGURATION -->
@if (
  (isUserAdmin || isUserDeskbirdAdmin || isUserOfficeAdmin) &&
  ([adminPartPrefix, "company"] | exec: hasAccessToPage)
) {
  <db-menu-item-with-children
    [hideIcons]="hideIcons"
    [link]="[
      adminPartPrefix,
      'company',
      companyId,
      officeId ? 'office' : 'office-redirect',
      officeId ? officeId : 'default',
      'meeting-room-settings',
    ]"
    [linkActiveOptions]="{ exact: true }"
    queryParamsHandling="merge"
    [style]="'admin'"
    imgSrc="/assets/icons/meeting-room-area-type.svg"
    [title]="menuItemLabels['meeting-room-settings']"
  >
    @if (
      [
        adminPartPrefix,
        "company",
        companyId,
        officeId ? "office" : "office-redirect",
        officeId ? officeId : "default",
        "meeting-room-settings",
        "general-setup",
      ] | exec: hasAccessToPage
    ) {
      <db-menu-item
        [style]="'admin'"
        [hideIcons]="hideIcons"
        [link]="[
          adminPartPrefix,
          'company',
          companyId,
          officeId ? 'office' : 'office-redirect',
          officeId ? officeId : 'default',
          'meeting-room-settings',
          'general-setup',
        ]"
        [title]="menuItemLabels['meeting-room-general-setup']"
      >
      </db-menu-item>
    }
    @if (
      cateringAndServicesEnabled &&
      ([
        adminPartPrefix,
        "company",
        companyId,
        officeId ? "office" : "office-redirect",
        officeId ? officeId : "default",
        "meeting-room-settings",
        "catering-and-services",
      ] | exec: hasAccessToPage)
    ) {
      <db-menu-item
        [style]="'admin'"
        [link]="[
          adminPartPrefix,
          'company',
          companyId,
          officeId ? 'office' : 'office-redirect',
          officeId ? officeId : 'default',
          'meeting-room-settings',
          'catering-and-services',
        ]"
        [title]="menuItemLabels['meeting-room-catering-and-services']"
      >
      </db-menu-item>
    }
    @if (
      kioskModeEnabled &&
      ([
        adminPartPrefix,
        "company",
        companyId,
        officeId ? "office" : "office-redirect",
        officeId ? officeId : "default",
        "meeting-room-settings",
        "kiosk",
      ] | exec: hasAccessToPage)
    ) {
      <db-menu-item
        [style]="'admin'"
        [link]="[
          adminPartPrefix,
          'company',
          companyId,
          officeId ? 'office' : 'office-redirect',
          officeId ? officeId : 'default',
          'meeting-room-settings',
          'kiosk',
        ]"
        [title]="menuItemLabels['meeting-room-kiosk']"
      >
      </db-menu-item>
    }
  </db-menu-item-with-children>
}

<!-- WORKFORCE ENGAGEMENT -->
@if (
  (isUserAdmin || isUserDeskbirdAdmin) &&
  ([adminPartPrefix, "company"] | exec: hasAccessToPage)
) {
  <db-menu-item-with-children
    [link]="[adminPartPrefix, 'company', companyId, 'hybrid-work-policies']"
    [hideIcons]="hideIcons"
    [linkActiveOptions]="{ exact: false }"
    queryParamsHandling="merge"
    [title]="menuItemLabels['hybrid-work-policies']"
    [style]="'admin'"
    imgSrc="/assets/icons/hybrid-work-policies-purple.svg"
  >
  </db-menu-item-with-children>
}

<!-- EVENTS -->
@if (
  hasPlanningAccess &&
  ([adminPartPrefix, "company", companyId, "office-events"]
    | exec: hasAccessToPage)
) {
  <db-menu-item-with-children
    [hideIcons]="hideIcons"
    [link]="[adminPartPrefix, 'company', companyId, 'office-events']"
    [linkActiveOptions]="{ exact: false }"
    queryParamsHandling="merge"
    [title]="menuItemLabels['office-events']"
    [style]="'admin'"
    imgSrc="/assets/icons/office-events-purple.svg"
  >
    <db-menu-item
      [style]="'admin'"
      *ngIf="
        hasPlanningAccess &&
        (isUserAdmin || isUserDeskbirdAdmin) &&
        ([adminPartPrefix, 'company', companyId, 'office-events']
          | exec: hasAccessToPage)
      "
      [hideIcons]="hideIcons"
      [link]="[
        adminPartPrefix,
        'company',
        companyId,
        'office-events-roles',
        'manage',
      ]"
      [title]="menuItemLabels['office-events-roles']"
    >
    </db-menu-item>
  </db-menu-item-with-children>
}

<!-- ANALYTICS -->
@if (
  ([adminPartPrefix, "company", companyId, "people-analytics", "people"]
    | exec: hasAccessToPage) ||
  ((isUserAdmin || isUserDeskbirdAdmin || isUserOfficeAdmin) &&
    ([
      adminPartPrefix,
      "company",
      companyId,
      officeId ? "office" : "office-redirect",
      officeId ? officeId : "default",
      "office-analytics",
      "office",
    ] | exec: hasAccessToPage))
) {
  <db-menu-item-with-children
    [hideIcons]="hideIcons"
    [link]="[
      adminPartPrefix,
      'company',
      companyId,
      officeId ? 'office' : 'office-redirect',
      officeId ? officeId : 'default',
      'office-analytics',
      'office',
    ]"
    [linkActiveOptions]="{ exact: true }"
    queryParamsHandling="merge"
    [title]="menuItemLabels['analytics']"
    [style]="'admin'"
    imgSrc="/assets/icons/analytics.svg"
  >
    @if (
      (isUserAdmin || isUserDeskbirdAdmin || isUserOfficeAdmin) &&
      ([
        adminPartPrefix,
        "company",
        companyId,
        officeId ? "office" : "office-redirect",
        officeId ? officeId : "default",
        "office-analytics",
        "office",
      ] | exec: hasAccessToPage)
    ) {
      <db-menu-item
        [style]="'admin'"
        [hideIcons]="hideIcons"
        [link]="[
          adminPartPrefix,
          'company',
          companyId,
          officeId ? 'office' : 'office-redirect',
          officeId ? officeId : 'default',
          'office-analytics',
          'office',
        ]"
        [title]="menuItemLabels['office-analytics']"
      >
      </db-menu-item>
    }
    @if (
      [adminPartPrefix, "company", companyId, "people-analytics", "people"]
        | exec: hasAccessToPage
    ) {
      <db-menu-item
        [style]="'admin'"
        [hideIcons]="hideIcons"
        [link]="[
          adminPartPrefix,
          'company',
          companyId,
          'people-analytics',
          'people',
        ]"
        [title]="menuItemLabels['schedule-analytics']"
      >
      </db-menu-item>
    }
  </db-menu-item-with-children>
}

<!-- USERS & GROUPS -->
@if (
  (isUserAdmin || isUserDeskbirdAdmin || isUserOfficeAdmin) &&
  ([adminPartPrefix, "company", companyId, "user"] | exec: hasAccessToPage)
) {
  <db-menu-item-with-children
    [link]="[adminPartPrefix, 'company', companyId, 'user']"
    [linkActiveOptions]="{ exact: true }"
    queryParamsHandling="merge"
    [style]="'admin'"
    [title]="menuItemLabels['users-and-groups']"
    [hideIcons]="hideIcons"
    imgSrc="/assets/icons/users.svg"
  >
    @if (
      [adminPartPrefix, "company", companyId, "user", "list"]
        | exec: hasAccessToPage
    ) {
      <db-menu-item
        [style]="'admin'"
        [hideIcons]="hideIcons"
        [link]="[adminPartPrefix, 'company', companyId, 'user', 'list']"
        [title]="menuItemLabels['user-settings']"
      >
      </db-menu-item>
    }
    @if (
      [adminPartPrefix, "company", companyId, "user", "user-group"]
        | exec: hasAccessToPage
    ) {
      <db-menu-item
        [style]="'admin'"
        [hideIcons]="hideIcons"
        [link]="[adminPartPrefix, 'company', companyId, 'user', 'user-group']"
        [title]="menuItemLabels['user-group-settings']"
      >
      </db-menu-item>
    }
  </db-menu-item-with-children>
}

<!-- INTEGRATIONS -->
@if (
  (isUserAdmin || isUserDeskbirdAdmin) &&
  ([adminPartPrefix, "company", companyId, "integrations"]
    | exec: hasAccessToPage)
) {
  <db-menu-item-with-children
    [hideIcons]="hideIcons"
    [style]="'integrations'"
    [link]="[adminPartPrefix, 'company', companyId, 'integrations']"
    [title]="menuItemLabels['integrations']"
    imgSrc="/assets/icons/integrations.svg"
  >
    @if (
      hasIntegrations &&
      ([adminPartPrefix, "company", companyId, "integrations", "list"]
        | exec: hasAccessToPage)
    ) {
      <db-menu-item
        [style]="'admin'"
        [hideIcons]="hideIcons"
        [link]="[adminPartPrefix, 'company', companyId, 'integrations', 'list']"
        [title]="menuItemLabels['authorized-apps']"
      >
      </db-menu-item>
    }
    <db-menu-item
      [style]="'admin'"
      [hideIcons]="hideIcons"
      [tooltip]="publicApiKeysTooltipLabel()"
      [link]="[
        adminPartPrefix,
        'company',
        companyId,
        'integrations',
        'public-api',
      ]"
      [title]="menuItemLabels['api-keys']"
    >
    </db-menu-item>
  </db-menu-item-with-children>
}

<!-- ADMINISTRATION -->
@if (isUserDeskbirdAdmin) {
  <db-menu-item-with-children
    [link]="[adminPartPrefix, 'general', 'settings']"
    [style]="'admin'"
    [title]="menuItemLabels['administration']"
    [hideIcons]="hideIcons"
    imgSrc="/assets/icons/deskbird.svg"
  >
  </db-menu-item-with-children>
}
@if (isUserDeskbirdAdmin) {
  <div class="company-switch-container">
    <db-company-switcher></db-company-switcher>
  </div>
}

<div class="footer border-top">
  <div class="support-info">
    <a
      class="cta-help-center small-text"
      href="https://deskbird.zendesk.com/hc/en-us"
      target="_blank"
    >
      <img
        src="assets/icons/help-center-purple.svg"
        alt="help"
        class="help-icon question-icon"
      />
      <div class="text" i18n="@@navigation-module|help-center">Help center</div>
      <img
        src="assets/icons/link-purple.svg"
        alt="link"
        class="help-icon link-icon"
      />
    </a>
  </div>

  <div class="user-info">
    <div class="menu-trigger" (click)="menu.toggle($event)">
      <db-user-info
        [userInfo]="userInfo"
        [avatarSize]="'standard'"
      ></db-user-info>
    </div>
  </div>
  <db-menu #menu [items]="userMenuItems" [popup]="true"></db-menu>
</div>
