import { HttpErrorResponse } from '@angular/common/http';
import { props } from '@ngrx/store';
import { IOfficeEvent, IUserGroup } from 'common-module';
import { forNamespace } from 'ngrx-action-bundles';
import { ApiErrorResponse, GridDatePeriodType, IUserInfo, OfficeEventAttendanceResponseType } from 'types';

const factory = forNamespace('OFFICE-EVENTS');

export const loadOfficeEventsBundle = factory.asyncAction(
  'loadOfficeEvents',
  props<{ companyId: string; fromDate?: string; untilDate?: string; gridDatePeriodType?: GridDatePeriodType }>(),
  props<{ events: IOfficeEvent[]; gridDatePeriodType?: GridDatePeriodType }>(),
  props<{ error: ApiErrorResponse }>()
);

export const loadInvitableUserGroupsBundle = factory.asyncAction(
  'loadInvitableUserGroups',
  props<{ companyId: string }>(),
  props<{ userGroups: IUserGroup[] }>(),
  props<{ error: HttpErrorResponse }>()
);

export const loadInvitableUsersBundle = factory.asyncAction(
  'loadInvitableUsers',
  props<{ companyId: string; }>(),
  props<{ users: IUserInfo[]; totalUsers: number; }>(),
  props<{ error: HttpErrorResponse }>()
);

export const selectOfficeEventBundle = factory.asyncActionWithCleanup(
  'selectOfficeEvent',
  props<{ officeEventId: string }>(),
  props<{ event: IOfficeEvent }>(),
  props<{ error: ApiErrorResponse }>()
);
export const reloadOfficeEventBundle = factory.asyncAction(
  'reloadOfficeEvent',
  props<{ officeEventId: string }>(),
  props<{ event: IOfficeEvent }>(),
  props<{ error: ApiErrorResponse }>()
);
export const saveOfficeEventBundle = factory.asyncAction(
  'saveOfficeEvent',
  props<{ event: Partial<IOfficeEvent> }>(),
  props<{ event: IOfficeEvent }>(),
  props<{ error: HttpErrorResponse }>()
);

export const deleteOfficeEventBundle = factory.asyncAction(
  'deleteOfficeEvent',
  props<{ event: IOfficeEvent }>(),
  props<{ event: IOfficeEvent }>(),
  props<{ error: HttpErrorResponse }>()
);

export const attendOfficeEventBundle = factory.asyncAction(
  'attendOfficeEvent',
  props<{ event: IOfficeEvent; eventId: string; response: OfficeEventAttendanceResponseType; userId: string }>(),
  props<{ event: IOfficeEvent }>(),
  props<{ error: HttpErrorResponse }>()
);

export const officeEventsBundles = [
  loadOfficeEventsBundle,
  loadInvitableUserGroupsBundle,
  loadInvitableUsersBundle,
  selectOfficeEventBundle,
  reloadOfficeEventBundle,
  saveOfficeEventBundle,
  deleteOfficeEventBundle,
  attendOfficeEventBundle
];
