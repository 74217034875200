import { Component, inject, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { interval, race, timer, Subscription } from "rxjs";
import { map, switchMap, take, takeWhile, startWith } from "rxjs/operators";
import { RouterModel } from "router-module";
import { NotificationModel } from "notification-module";
import { TeamsService } from "../services/teams.service";
import { LoadingTenseState } from "db-ui";
import { AuthModel } from "../+store/model";
import { AuthService } from "../services/auth.service";
import { Lottie } from "lottie-module";
import { IEmailCheck } from "common-module";
import { TypedAction } from "@ngrx/store/src/models";

@Component({
  selector: "db-free-trial",
  templateUrl: "./free-trial.component.html",
  styleUrls: ["./free-trial.component.scss"],
})
export class FreeTrialComponent implements OnDestroy {
  routerModel = inject(RouterModel);
  authModel = inject(AuthModel);
  notificationModel = inject(NotificationModel);
  teamsService = inject(TeamsService);
  authService = inject(AuthService);
  router = inject(Router);

  showLottie$ = interval(0).pipe(
    take(1),
    map(() => true),
    startWith(false),
  );

  isLoading = true;
  formValue = { email: "", accept: false };
  LoadingTenseState = LoadingTenseState;
  Lottie = Lottie;

  private pollCount = 0;
  private readonly POLL_FREQUENCY_IN_SECONDS = 1;
  private readonly MAX_POLLS = 20;
  private pollingSubscription: Subscription | undefined;
  private queryParamsSubscription: Subscription | undefined;

  constructor() {
    this.initializeForm();
  }

  private initializeForm() {
    this.queryParamsSubscription = this.routerModel.selectors.queryParams$
      .pipe(take(1))
      .subscribe((qp) => {
        const email = qp?.["email"] as string | undefined;
        if (email) {
          this.formValue = { email, accept: true };
          this.isLoading = true;
          this.startPolling(email);
        } else {
          this.redirectToCheckIn();
        }
      });
  }

  private redirectToCheckIn() {
    console.log("No email has been passed, redirecting to the check-in route");
    this.routerModel.actions.dispatch.navigate({
      commands: ["login", "check-in"],
    });
  }

  private startPolling(email: string) {
    this.pollCount = 0; // Reset count before polling starts

    this.pollingSubscription = timer(0, this.POLL_FREQUENCY_IN_SECONDS * 1000)
      .pipe(
        take(this.MAX_POLLS),
        switchMap(() => {
          this.pollCount++;
          return this.refreshCompanyCreation().pipe(
            switchMap(() => this.checkCompanyExists(email)),
          );
        }),
      )
      .subscribe({
        next: (result) => this.handleEmailCheckResult(email, result),
        complete: () => this.stopPolling(),
      });
  }

  private stopPolling() {
    this.pollCount = 0;
    this.routerModel.actions.dispatch.navigate({
      commands: ["login"],
    });
  }

  private checkCompanyExists(email: string) {
    this.authModel.actions.dispatch.checkEmail({ email });
    return race(
      this.authModel.actions.listen.checkEmailSuccess$,
      this.authModel.actions.listen.checkEmailFailure$,
    ).pipe(take(1));
  }

  private refreshCompanyCreation() {
    this.authModel.actions.dispatch.refreshCompanyCreation();
    return race(
      this.authModel.actions.listen.refreshCompanyCreationSuccess$,
      this.authModel.actions.listen.refreshCompanyCreationFailure$,
    ).pipe(take(1));
  }

  private handleEmailCheckResult(
    email: string,
    result:
      | ({
          emailCheckResult: IEmailCheck;
        } & TypedAction<"[[AUTH]] checkEmailSuccess">)
      | ({
          error: any;
          email: string;
        } & TypedAction<"[[AUTH]] checkEmailFailure">),
  ) {
    if (result?.type == "[[AUTH]] checkEmailFailure") {
      console.error("Error checking company existence:", result.error);
      return;
    }

    const { companyExists } = result.emailCheckResult;
    if (companyExists) {
      console.log(`Company exists so user ${email} can log in`);
      this.router.navigate(["/login/check-in"], {
        queryParams: { email },
        queryParamsHandling: "preserve",
      });
    }
  }

  ngOnDestroy() {
    this.pollingSubscription?.unsubscribe();
    this.queryParamsSubscription?.unsubscribe();
  }
}
