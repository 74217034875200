import { inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { FeatureAccess, FeatureAccessType } from "types";
import { AuthModel } from "../+store/model";
import { FeatureFlagModel } from "../+store/feature-flag/model";

/**
 * Service responsible for managing feature access control.
 * Provides methods to check if user has access to specified features.
 */
@Injectable({
  providedIn: "root",
})
export class FeatureAccessService {
  private readonly authModel = inject(AuthModel);
  private readonly featureFlagModel = inject(FeatureFlagModel);

  /**
   * Checks if user has access to specified feature(s)
   * @param feature Single feature or array of features to check
   * @param condition Condition to apply when checking multiple features ('some' | 'every')
   * @returns Observable<boolean> indicating if user has access
   */
  hasFeatureAccess(
    feature: FeatureAccessType | FeatureAccessType[],
    condition: "some" | "every" = "some",
  ): Observable<boolean | null> {
    return this.featureFlagModel.featureEnableMPPS$.pipe(
      switchMap((mppsEnabled) => {
        if (!mppsEnabled) {
          return of(true);
        }

        return this.authModel.userFeatureAccess$.pipe(
          map((featureAccess: FeatureAccess | null) => {
            // Return null if featureAccess is not loaded yet
            if (!featureAccess) return null;

            const features = Array.isArray(feature) ? feature : [feature];
            const hasAccess =
              condition === "some"
                ? features.some((f) => featureAccess.features.includes(f))
                : features.every((f) => featureAccess.features.includes(f));
            return hasAccess;
          }),
        );
      }),
    );
  }
}
